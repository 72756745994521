import { useMemo } from 'react';
import { type CoordinationClientType } from '@atlassiansox/engagekit-ts';

import { useSessionData } from '@confluence/session-data';
import { getWorkspaceId } from '@confluence/analytics-web-client';
import { useAnalyticsEnvironment } from '@confluence/choreographer-services';

import { getCoordinationClient } from './getCoordinationClient';

export const useCoordinationClient = (withChoreographer = true): CoordinationClientType => {
	const { cloudId, activationId, environment } = useSessionData();

	const workspaceId = getWorkspaceId(cloudId, activationId);

	const disableEngagementForTests =
		sessionStorage.getItem('confluence.disable-engagement-for-tests') === 'true';
	const envType = useAnalyticsEnvironment(environment);

	return useMemo(
		() =>
			getCoordinationClient(
				disableEngagementForTests,
				cloudId,
				workspaceId,
				withChoreographer,
				envType,
			),
		[disableEngagementForTests, cloudId, workspaceId, withChoreographer, envType],
	);
};
